<template>
  <div>
    <br />
    <div class="form-container">
      <b-row>
        <b-col md="auto">
          <label>
            Category
          </label>
        </b-col>

        <b-col md="auto">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="formData.category"
            :options="categories"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-col>
      </b-row>
      <br />
      <label>Name</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.name"
      ></b-form-input
      ><br /><br />

      <label>Description</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.description"
      ></b-form-input
      ><br /><br />

      <label>Type</label> <br />
      <b-form-select
        :options="types"
        v-model="formData.type"
        class="w-auto"
      ></b-form-select>
      <br /><br />

      <label>Video</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.video"
      ></b-form-input
      ><br /><br />

      <b-row class="mt-4-5">
        <b-col md="auto">
          <label>Thumbnail</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__top">
                <img :src="formData.thumbnail.src" alt="img" style="max-height:108px" />
                <div class="img-remove" @click="removeThumbnail()" v-if="formData.thumbnail.src !='/img/thumbnail.png'">
                  X Remove
                </div>
              </div>

              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="file-image"
                  id="file-image"
                  class="hidden-input"
                  @change="uploadThumbnail($event)"
                />
                <label for="file-image" class="file-upload">
                  <fa-icon icon="upload" class="my-icon" />
                  Upload Thumbnail
                </label>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="auto">
          <label>Resource</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="resource"
                  id="resource"
                  class="hidden-input"
                  @change="uploadResource($event)"
                />
                <label for="resource" class="file-upload">
                  <fa-icon icon="upload" class="mr-1" />
                  Upload Resource
                </label><br /> 
                {{ formData.resource.name }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <my-button type="blue" size="lg" block class="mt-4-5" rounded v-if="!showLoading" @click="save">
        SIMPAN & LANJUTKAN
      </my-button>
      <my-button type="blue" size="lg" block class="mt-4-5" rounded disabled v-else>
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {  
      showLoading: false,
      formData: {
        name: '',
        description: '',
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null
        },
        resource: {
          data: null,
          name: ""
        },
        type: 1,
        video: "",
        category: []
      },
      types: [],
      categories:[]
    };
  },

  methods: {
    ...mapActions({
      createMarketingKit: types.CREATE_MARKETING_KIT,
      getTypeList: types.GET_MARKETING_TYPE_LIST,
      getCategoryList: types.GET_MARKETING_CATEGORY_LIST,
    }),
    save() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("description", this.formData.description);
      newFormData.append("name", this.formData.name);
      newFormData.append("type", this.formData.type);
      newFormData.append("video", this.formData.video);
      newFormData.append("thumbnail", this.formData.thumbnail.data);
      newFormData.append("resource", this.formData.resource.data);
      for (var i = 0; i < this.formData.category.length; i++) {
        newFormData.append("category"+i, this.formData.category[i]);
      }
      this.createMarketingKit(newFormData)
        .then(response => {
          this.showLoading = false;
          this.$router.push("/marketing-kit/edit/"+response[0]);
        })
        .catch(error => {
          this.showLoading = false;
          this.toastError('b-toaster-top-center', error);
        });
    },
    toastSuccess(toaster, append = false) {
      this.$bvToast.toast('Marketing kit Berhasil disimpan', {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    removeThumbnail(){
      this.formData.thumbnail.data = null;
      this.formData.thumbnail.src = '/img/thumbnail.png';
    },
    uploadThumbnail(event) {
      var fileData = event.target.files[0];
      this.formData.thumbnail.data = fileData;
      this.formData.thumbnail.src = URL.createObjectURL(fileData);
    },
    uploadResource(event) {
      var fileData = event.target.files[0];
      this.formData.resource.data = fileData;
      this.formData.resource.name = fileData.name;
    },
    typeList(level_id = null) {
      this.getTypeList()
        .then(response => {
          var res = response;
          this.types = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name
            };
            this.types.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    categoryList() {
      this.getCategoryList()
        .then(response => {
          var res = response;
          this.categories = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name,
            };
            this.categories.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    }
  },

  mounted() {
    this.typeList();
    this.categoryList();
  }
};
</script>

<style>

.button-ya {
  border: 1px solid #3bbaed;
  color: #3bbaed;
  margin-right: 11px;
  background: #fff;
  min-width: 22px;
  margin-top: 11px;
}

@media (min-width: 576px) {
  #modal-icon .modal-lg {
    max-width: 544px;
  }
 
}
</style>
